import React, { Suspense } from "react"
import ReactDOM from "react-dom"
import { BrowserRouter } from "react-router-dom";
import App from "App";
import { SnackbarProvider } from "notistack";

ReactDOM.render(
    <Suspense fallback="loading...">
        <SnackbarProvider>
            <BrowserRouter>
                <App />
            </BrowserRouter>
        </SnackbarProvider>
    </Suspense>,
    document.getElementById("root")
);
