import { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types"

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKButton from "components/MKButton";

// typed-js
import * as Typed from "typed.js";

// SwiperJS
import SwiperCore, { Autoplay, Navigation } from "swiper";
// SwiperJS react components
import { Swiper, SwiperSlide } from "swiper/react/swiper-react";
// SwiperJS styles
import "swiper/swiper.min.css";
import "swiper/modules/navigation/navigation.min.css";

// Pricing page components
import SliderHeader from "contents/Components/SliderHeader"

// import bgImage from "assets/img/background.jpg"
import bgImage from "assets/img/slider-bgp.png"

import bgBanner1 from "assets/img/bgBanner1.png"
import bgBanner2 from "assets/img/bgBanner2.png"
import bgBanner3 from "assets/img/bgBanner3.png"

import SvgBanner1 from "assets/img/bgBanner1.svg"
import SvgBanner2 from "assets/img/bgBanner2.svg"
import SvgBanner3 from "assets/img/bgBanner3.svg"

// Material Kit 2 PRO React base styles
import breakpoints from "assets/theme/base/breakpoints";

function Header({ labels, tagline }) {
	const typedJSRef = useRef(null)
	const [actionView, setActionView] = useState(false)

	SwiperCore.use([Autoplay, Navigation]);

	// Setting up typedJS
	useEffect(() => {
		const typedJS = new Typed(typedJSRef.current, {
			strings: labels,
			typeSpeed: 70,
			backSpeed: 70,
			backDelay: 200,
			startDelay: 500,
			loop: true,
		});

		return () => typedJS.destroy();
	}, []);

	useEffect(() => {
		function displayActionNavbar() {
			if (window.innerWidth < breakpoints.values.sm) {
				setActionView(true)
			} else {
				setActionView(false)
			}
		}

		window.addEventListener('resize', displayActionNavbar)
		displayActionNavbar()

		return () => window.removeEventListener('resize', displayActionNavbar)
	}, [])

	return (
		<MKBox id="header" component="section" position="relative">
			<MKBox
				display="flex"
				alignItems={{ md: "center", xs: "flex-end" }}
				justifyContent="center"
				minHeight={{ xs: "80vh", sm: "80vh", md: "100vh", lg: "88vh" }}
				p={5}
				sx={{
					backgroundImage: `url(${bgImage})`,
					backgroundSize: "cover",
					backgroundPosition: "center",
				}}
			>
				<Container>
					<MKBox
						width="100%"
						display="flex"
						flexDirection={{ md: "row", xs: "column-reverse" }}
						justifyContent={{ xs: "center", md: "flex-end" }}
						alignItems="center"
					>
						<MKBox
							width={{ md: "60%", xs: "100%" }}
							display="flex"
							flexDirection="column"
							alignItems={{ xs: "center", md: "flex-start" }}
						// justifyContent={{ md: "flex-start", xs: "center" }}
						>
							<MKTypography
								variant="h1"
								// color="white"
								sx={({ breakpoints, typography: { size } }) => ({
									[breakpoints.down("md")]: {
										fontSize: size["3xl"],
									},
								})}
							>
								<span ref={typedJSRef} />
							</MKTypography>
							<MKTypography
								variant="body1"
								// color="white"
								textAlign={{ xs: "center", md: "left" }}
								mt={1}
								mb={6}
							>
								{tagline}
							</MKTypography>
							<MKBox
								display="flex"
								flexDirection="row"
								justifyContent="center"
								alignItems="center"
							>
								{actionView && (
									<MKBox mr={{ xs: 1, md: 0 }}>
										<MKButton
											component="a"
											// to={{ pathname: "/register" }}
											href="https://mon.mabrur.tours/register"
											target="_blank"
											color="warning"
											size="large"
										>
											SIGN UP
										</MKButton>
									</MKBox>
								)}
								<MKButton
									component="a"
									href="https://mon.mabrur.tours/login"
									target="_blank"
									color={actionView ? "secondary" : "warning"}
									size="large"
								>
									SIGN IN
								</MKButton>
							</MKBox>
						</MKBox>
						<MKBox width={{ md: "40%", xs: "100%" }} mb={{ xs: 5 }}>
							<Swiper
								autoplay={{ delay: 4000 }}
								speed={300}
								spaceBetween={0}
								slidesPerView={1}
								loop
							>
								<SwiperSlide>
									<SliderHeader
										image={SvgBanner1}
										title="Banner 1"
									/>
								</SwiperSlide>
								<SwiperSlide>
									<SliderHeader
										image={SvgBanner2}
										title="Banner 2"
									/>
								</SwiperSlide>
								<SwiperSlide>
									<SliderHeader
										image={SvgBanner3}
										title="Banner 3"
									/>
								</SwiperSlide>
							</Swiper>
						</MKBox>
					</MKBox>

					{/* <Grid
						container
						spacing={3}
						justifyContent="center"
						alignItems="center"
					>
						<Grid item xs={12} md={7} lg={7}>
							<MKTypography
								variant="h1"
								color="white"
								sx={({ breakpoints, typography: { size } }) => ({
									[breakpoints.down("md")]: {
										fontSize: size["3xl"],
									},
								})}
							>
								<span ref={typedJSRef} />
							</MKTypography>
							<MKTypography
								variant="body1"
								color="white"
								mt={1}
								mb={6}
							>
								{tagline}
							</MKTypography>
						</Grid>
						<Grid item xs={12} md={5} lg={5} mt={{ xs: 5 }}>
							<Swiper
								autoplay={{ delay: 4000 }}
								speed={800}
								spaceBetween={0}
								slidesPerView={1}
								loop
							>
								<SwiperSlide>
									<SliderHeader
										image={bgBanner1}
										title="Banner 1"
									/>
								</SwiperSlide>
								<SwiperSlide>
									<SliderHeader
										image={bgBanner3}
										title="Banner 3"
									/>
								</SwiperSlide>
								<SwiperSlide>
									<SliderHeader
										image={bgBanner2}
										title="Banner 2"
									/>
								</SwiperSlide>
							</Swiper>
						</Grid>
					</Grid> */}
				</Container>
			</MKBox>
		</MKBox>
	)
}

Header.defaultProps = {
	labels: ["Paket Umroh", "Paket Haji"],
	tagline: "The time is now for it to be okay to be great. People in this world shun people for being great. For being a bright color."
}

Header.propTypes = {
	tagline: PropTypes.string,
	labels: PropTypes.arrayOf(PropTypes.string)
}

export default Header