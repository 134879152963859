import Icon from "@mui/material/Icon";
import Container from "@mui/material/Container"

import Home from "contents/Home"
import Register from "contents/Register";
import PrivacyPolicy from "contents/PrivacyPolicy";

import secureStorage from "libs/secureStorage";

const routes = [
	{
		name: "home",
		icon: <Icon>home</Icon>,
		route: "/",
		component: <Home />,
		key: "home",
		show: false
	},
	{
		name: "PRODUK",
		icon: <Icon fontWeight="medium">inventory</Icon>,
		route: "/product",
		component: <Register />,
		key: "product",
		show: true
	},
	{
		name: "DAFTAR",
		route: "/register",
		key: "register",
		show: false,
		component: <Register />
	},
	{
		name: "PRIVACY POLICY",
		route: "/privacy-policy",
		key: "privacy-policy",
		show: false,
		component: <PrivacyPolicy />
	}
]

const getMenus = () => {
	const user = secureStorage.getItem('user')
	const menuAccount = user ? [
		{
			name: user && user.name ? user.name : "Profil",
			icon: <Icon fontWeight="medium">person</Icon>,
			route: "/account",
			component: <Container />,
			key: "account",
			show: false
		}
	] : [
		{
			name: "Login",
			icon: <Icon fontWeight="medium">login</Icon>,
			route: "/login",
			component: <Container />,
			key: "login",
			show: false
		}
	]
	let menus = [
		{
			name: "HOME",
			route: "/",
			key: "home",
			show: true,
			type: "target"
		},
		{
			name: "SERVICE",
			href: "#services",
			key: "services",
			show: true,
			type: "scroll"
		},
		{
			name: "ABOUT US",
			href: "#aboutus",
			key: "aboutus",
			show: true,
			type: "scroll"
		},
		{
			name: "CONTACT",
			href: "#contactus",
			key: "contact",
			show: true,
			type: "scroll"
		}
	]

	return menus
}

export { routes, getMenus }