/* eslint-disable react/prop-types */
import { useState, useEffect, useRef, forwardRef } from "react"
import PropTypes from "prop-types"

import Fade from '@mui/material/Fade';

import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

import LOGO from "assets/img/logoApp.png"
import LOGOApp from "assets/img/logo.png"
import DefaultNavbar from "examples/Navbars/DefaultNavbar";

const FadeCustom = forwardRef((props, ref) => {
	return <Fade in={props.in} ref={ref}>{props.children}</Fade>;
})

function Navbar({ show, routes, action, brand }) {
	// const { name } = brand
	const [visible, setVisible] = useState(false)

	const fadeInRef = useRef()
	const fadeExitRef = useRef()

	const controlNavbar = () => {
		if (window.scrollY > 50) setVisible(true)
		else setVisible(false)
	}

	useEffect(() => {
		document.addEventListener('scroll', controlNavbar)

		return () => {
			document.removeEventListener('scroll', controlNavbar)
		}
	}, [])

	const btnAction = {
		action: {
			route: "https://mon.mabrur.tours/register",
			label: "SIGN UP",
			color: "warning",
		}
	}

	return (
		<>
			{show && (
				<FadeCustom in={!visible} ref={fadeExitRef}>
					<MKBox sx={{ backgroundColor: "transparent" }}>
						<DefaultNavbar
							routes={routes}
							brand={
								<MKBox
									display="flex"
									justifyContent="flex-start"
									alignItems="center"
									// ml={{ xs: 0, md: 6 }}
									ml={10}
								>
									<MKBox
										component="img"
										src={`${LOGOApp}`}
										sx={{
											height: 60,
											width: 150,
											// marginRight: "15px"
										}}
									/>
									{/* <MKTypography
										variant="body2"
										fontWeight="medium"
										textTransform="uppercase"
									// color="white"
									>
										MABRUR
									</MKTypography> */}
								</MKBox>
							}
							{...(action && btnAction)}
							transparent
						/>
					</MKBox>
				</FadeCustom>
			)}
			<FadeCustom in={visible} ref={fadeInRef}>
				<MKBox
					bgColor="white"
					shadow="md"
					pl={3}
					pr={6}
					sx={{
						position: "sticky",
						top: 0,
						zIndex: 999,
					}}
				>
					<DefaultNavbar
						routes={routes}
						brand={
							<MKBox
								display="flex"
								justifyContent="flex-start"
								alignItems="center"
								ml={{ xs: 0, md: 6 }}
							>
								<MKBox
									component="img"
									src={`${LOGOApp}`}
									sx={{
										height: 60,
										width: 150,
										// marginRight: "15px"
									}}
								/>
								{/* <MKTypography
									variant="body2"
									fontWeight="medium"
									textTransform="uppercase"
								>
									MABRUR
								</MKTypography> */}
							</MKBox>
						}
						{...(action && btnAction)}
						transparent
						relative
					/>
				</MKBox>
			</FadeCustom>
			{/* <Fade in={visible} timeout={500}>
			</Fade> */}
		</>
	)
}

Navbar.defaultProps = {
	show: true,
	action: true
}

Navbar.propTypes = {
	routes: PropTypes.array.isRequired,
	brand: PropTypes.object,
	show: PropTypes.bool,
	action: PropTypes.bool
}

export default Navbar