import PropTypes from "prop-types"

import Container from "@mui/material/Container"
import Grid from "@mui/material/Grid"

import MKBox from "components/MKBox"
import MKTypography from "components/MKTypography"

// Material Kit 2 PRO React examples
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";
import CenteredBlogCard from "examples/Cards/BlogCards/CenteredBlogCard";

import bgImage from "assets/img/arab.png"
import AboutUsIcon from "assets/img/aboutus.png"
import NotificationIcon from "assets/img/notification.png"

function AboutUs({about}) {

	return (
		<MKBox
			id="aboutus"
			component="section"
			position="relative"
			bgColor="#f7f9fa"
			p={5}
		>
			<Container>
				<Grid container spacing={3}>
					<Grid item xs={12} lg={4} sx={{ mt: { xs: 3, lg: 0 } }}>
						<MKBox
							component="img"
							src={AboutUsIcon}
							width="100%"
							position="relative"
							zIndex={10}
							sx={{
								backgroundSize: "cover",
							}}
						/>
					</Grid>
					<Grid item xs={12} md={2} lg={2} />
					<Grid item xs={12} md={6} lg={6} >
						<MKTypography variant="h3">
							{about.excerpt}
						</MKTypography>
						<MKBox pt={3}>
							<MKTypography variant="body2" color="text" fontWeight="regular">
								{about.message}
							</MKTypography>
						</MKBox>

						{/* <Grid container justifyContent="flex-start">
							<Grid item xs={12} md={6}>
								<MKBox mb={5}>
									<DefaultInfoCard
										icon="public"
										title="Pendaftaran"
										description="Melakukan pendaftaran melalui agen atau app mobile kami"
									/>
								</MKBox>
							</Grid>
							<Grid item xs={12} md={6}>
								<MKBox mb={5}>
									<DefaultInfoCard
										icon="payments"
										title="Pembayaran"
										description="bisa di lakukan dengan angsuran atau cicilan tanpa tenor"
									/>
								</MKBox>
							</Grid>
							<Grid item xs={12} md={6}>
								<MKBox mb={{ xs: 5, md: 0 }}>
									<DefaultInfoCard
										icon="apps"
										title="Mobile App"
										description="Tersedia aplikasi mobile untuk memudahkan bagi jamaah"
									/>
								</MKBox>
							</Grid>
							<Grid item xs={12} md={6}>
								<MKBox mb={{ xs: 5, md: 0 }}>
									<DefaultInfoCard
										icon="3p"
										title="Improved platform"
										description="We get insulted by others, lose trust for those We get back freezes"
									/>
								</MKBox>
							</Grid>
						</Grid> */}
					</Grid>
				</Grid>
			</Container>
		</MKBox>
	)
}

AboutUs.defaultProps = {
	about: {
		excerpt: "We are professional tour Travel",
		message: "Enjoy your Holiday with our best services"
	}
}

AboutUs.propTypes = {
	about: PropTypes.object
}

export default AboutUs