import PropTypes from "prop-types";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

function SliderHeader({ image, title }) {

	return (
		<MKBox position="relative" mx={3}>
			<MKBox
				// height="30vh"
				component="img"
				src={image}
				alt={title}
				width="100%"
				position="relative"
				zIndex={999}
				sx={{
					backgroundSize: "cover",
				}}
			/>
		</MKBox>
	)
}

SliderHeader.propTypes = {
	image: PropTypes.string.isRequired,
	title: PropTypes.string.isRequired,
}

export default SliderHeader