import React from "react"
import { Link } from "react-router-dom"

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Autocomplete from "@mui/material/Autocomplete";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKButton from "components/MKButton";
import MKInput from "components/MKInput";
import MKDatePicker from "components/MKDatePicker";

import SimpleFooter from "examples/Footers/SimpleFooter";

// Images
// import bgImage from "assets/images/bg-coworking.jpeg";
// import bgImage from "assets/img/mabrur6.png"

import ModalNotif from "contents/Components/ModalNotif";
import Navbar from "contents/Components/Navbar";
import Footer from "contents/Components/Footer"

import Config from "config"
import useAxios from "libs/useAxios";
import secureStorage from "libs/secureStorage";

import * as EmailValidator from 'email-validator'
import moment from "moment"

import LOGO from "assets/img/logoApp.png"
import bgImage from "assets/img/slider-bgp.png"
import DepartureIcon from "assets/img/departure2.png"

import { getMenus } from "routes";
import footerRoutes from "footer.routes";
import DefaultNavbar from "examples/Navbars/DefaultNavbar";

class Register extends React.Component {

	constructor(props) {
		super(props)
		this.state = {
			name: "",
			email: "",
			password: "",
			repassword: "",
			phone: "",
			placeBirth: "",
			dateBirth: "",
			address: "",
			kodepos: "",
			nik: "",
			nokk: "",
			invitationId: "",

			filename: "",
			file: null,

			gender: "",
			statusPerson: null,
			province: null,
			district: null,

			genderOpen: false,
			statusPersonOpen: false,
			provinceOpen: false,
			districtOpen: false,

			error: [],
			success: [],

			provinces: [],
			districts: [],
			statusPersons: [
				{ id: 1, label: "Lajang" },
				{ id: 2, label: "Menikah" },
				{ id: 3, label: "Janda" },
				{ id: 4, label: "Duda" },
				{ id: 5, label: "Lainnya" }
			],

			redirect: null,
			isLoading: false,
			disabledBtnSubmit: false,

			parallax: null
		}

		this.inputRef = React.createRef()
		this.modalNotifRef = React.createRef()
	}

	componentDidMount() {
		this.inputRef.current && this.inputRef.current.focus()
		this.loadProvince()
	}

	loadProvince = () => {
		useAxios()
			.post(`${Config.ApiUrl}/region/province/dropdown`, { countryId: 1 })
			.then(response => {
				const data = response.data
				const provinces = data.map(item => ({
					id: item.id,
					label: item.name
				}))

				this.setState({ provinces })
			})
			.catch(err => {
				console.log(err)
			})
	}

	loadDistrict = (provinceId) => {
		useAxios()
			.post(`${Config.ApiUrl}/region/district/dropdown`, { provinceId })
			.then(response => {
				const data = response.data
				// console.log(data)
				const districts = data.map(item => ({ id: item.id, label: item.name }))
				this.setState({ districts })
			})
			.catch(err => {
				console.log(err)
			})
	}

	handleChange = (e) => {
		const { id, value } = e.target;
		this.setState({
			[id]: value
		})
	}

	handleblur = (e) => {
		const user = this.state.user
		const { id, value } = e.target
		// eslint-disable-next-line no-empty
		if (value) {
			if (id === "email" && !EmailValidator.validate(value)) {
				this.setState({
					success: { ...this.state.success, [e.target.id]: false },
					error: { ...this.state.error, [e.target.id]: true }
				})
			}
			else {
				this.setState({
					success: { ...this.state.success, [e.target.id]: true },
					error: { ...this.state.error, [e.target.id]: false }
				})
			}
		}
		else {
			this.setState({
				success: { ...this.state.success, [e.target.id]: false },
				error: { ...this.state.error, [e.target.id]: true }
			})
		}
	}

	handleSumit = () => {
		const success = this.state.success

		if (this.state.password !== this.state.repassword) {
			success.repassword = false
		}

		if (
			success.name &&
			success.phone &&
			success.email &&
			success.placeBirth &&
			success.password &&
			success.repassword &&
			success.kodepos &&
			success.address &&
			success.gender &&
			success.statusPerson &&
			success.province &&
			success.district &&
			success.invitationId
		) {
			this.sendData()
		}
		else {
			this.modalNotifRef.current.setShow({
				modalTitle: "Gagal",
				modalMessage: "Data form masih belum lengkap, Silahkan di cek kembali !",
				onClose: () => {
					this.inputRef.current.focus()
				}
			})
		}
	}

	resetForm = () => {
		this.setState({
			disabledSubmit: false,
			name: "",
			gender: "",
			statusPerson: null,
			placeBirth: "",
			dateBirth: "",
			phone: "",
			email: "",
			nik: "",
			nokk: "",
			province: null,
			district: null,
			districts: [],
			address: "",
			kodepos: "",
			file: null,
			filename: "",
			password: "",
			repassword: "",
			invitationId: "",
			error: [],
			success: []
		})
	}

	sendData = () => {
		this.setState({
			disabledSubmit: true
		})
		const formData = new FormData()

		formData.append("name", this.state.name)
		formData.append("gender", this.state.gender)
		formData.append("statusPersonId", this.state.statusPerson?.id)
		formData.append("placeBirth", this.state.placeBirth)
		formData.append("dateBirth", this.state.dateBirth)
		formData.append("phone", this.state.phone)
		formData.append("nik", this.state.nik)
		formData.append("nokk", this.state.nokk)
		formData.append("countryId", 1)
		formData.append("provinceId", this.state.province?.id)
		formData.append("districtId", this.state.district?.id)
		formData.append("address", this.state.address)
		formData.append("kodepos", this.state.kodepos)
		formData.append("email", this.state.email)
		formData.append("isActive", false)
		formData.append("password", this.state.password)
		formData.append("merchantId", Config.merchantId)
		formData.append("roleId", 4)
		formData.append('invitationId', this.state.invitationId)
		formData.append("image", this.state.file)

		useAxios()
			.post(`${Config.ApiUrl}/user/register`, formData)
			.then(response => {
				this.modalNotifRef.current.setShow({
					modalTitle: "Sukses",
					modalMessage: response.data,
					onClose: () => {
						this.resetForm()
					}
				})
			})
			.catch(err => {
				this.setState({ disabledSubmit: false })
				if (err.response) {
					this.modalNotifRef.current.setShow({
						modalTitle: "Gagal",
						modalMessage: err.response.data ? err.response.data : "Terjadi kesalahan pada system",
					})
				}
				// eslint-disable-next-line no-empty
				else {
					this.modalNotifRef.current.setShow({
						modalTitle: 'Gagal',
						modalMessage: "Koneksi jaringan terputus",
					})
				}
			})
	}

	render() {
		let routes = getMenus().map(item => ({
			...item,
			show: false,
		}))
		routes.push({
			name: "HOME",
			route: "/",
			key: "home",
			show: true,
			type: "target"
		})

		const footerMenus = footerRoutes.menus.filter(item => item.type === "internal")
		return (
			<>
				<Navbar routes={routes} action={false} />
				<MKBox component="section" position="relative">
					<ModalNotif ref={this.modalNotifRef} />
					<MKBox
						display="flex"
						minHeight="30vh"
						p={5}
						sx={{
							backgroundImage: ` url(${bgImage})`,
							backgroundSize: "cover",
							backgroundPosition: "center",
						}}
					>
						<Container>
							<Grid
								container
								item
								xs={12}
								md={12}
								justifyContent={{ xs: "center", md: "center" }}
								sx={{ textAlign: { xs: "center", md: "center" } }}
							>
								<MKBox
									display="flex"
									flexDirection="column"
									justifyContent="flex-start"
									alignItems="center"
								>
									{/* <MKBox
										component="img"
										src={DepartureIcon}
										alt="departure"
										maxWidth="5.6rem"
									/> */}
									<MKTypography
										variant="h3"
										// color="white"
										sx={({ breakpoints, typography: { size } }) => ({
											[breakpoints.down("md")]: {
												fontSize: size["3xl"],
											},
										})}
									>
										Dafkankan diri anda untuk menjadi bagian dari kami
									</MKTypography>
									<MKTypography
										variant="body1"
										// color="white"
										mt={1}
										opacity={0.8}
									>
										Silahkan lengkapi form pendaftaran di bawah ini
									</MKTypography>
								</MKBox>
							</Grid>
						</Container>
					</MKBox>
					<Card
						sx={{
							p: 2,
							mx: { xs: 5, lg: 10 },
							mt: -4,
							mb: 4,
							backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.9),
							backdropFilter: "saturate(200%) blur(30px)",
							boxShadow: ({ boxShadows: { xxl } }) => xxl,
						}}
					>
						<MKBox
							width="100%"
							display="flex"
							justifyContent="center"
							lineHeight={1}
							py={2}
						>
							<MKBox
								bgColor="info"
								// px={4}
								// pt={0}
								// pb={0.5}
								py={1}
								px={4}
								mx="auto"
								mt={-1.375}
								borderRadius="section"
								lineHeight={1}
							>
								<MKTypography
									variant="body"
									textTransform="uppercase"
									fontWeight="medium"
									color="white"
								>
									REGISTER
								</MKTypography>
							</MKBox>
						</MKBox>
						<MKBox p={2}>
							<MKBox component="form" role="form">
								<Grid container spacing={3}>
									<Grid item xs={12} lg={6} md={6}>
										<MKBox mb={2}>
											<MKInput
												id="name"
												type="text"
												inputRef={this.inputRef}
												placeholder="Nama"
												value={this.state.name}
												onChange={this.handleChange}
												onBlur={this.handleblur}
												success={this.state.success ? this.state.success.name : false}
												error={this.state.error ? this.state.error.name : false}
												fullWidth
											/>
										</MKBox>
										<MKBox mb={2}>
											<Autocomplete
												disableClearable
												value={this.state.gender}
												open={this.state.genderOpen}
												onOpen={() => this.setState({ genderOpen: true })}
												onClose={() => this.setState({ genderOpen: false })}
												onFocus={() => this.setState({ genderOpen: true })}
												options={["Male", "Female"]}
												onChange={(e, value) => {
													if (value) {
														this.setState({
															gender: value,
															error: { ...this.state.error, gender: false },
															success: { ...this.state.success, gender: true }
														})
													}
												}}
												sx={{
													".MuiAutocomplete-input": {
														padding: "7.5px 5px 7.5px 8px !important"
													},
													".MuiOutlinedInput-root": {
														padding: "4px !important"
													}
												}}
												isOptionEqualToValue={(option, value) => option === value}
												renderInput={(params) => <MKInput placeholder="Jenis Kelamin" {...params} />}
											/>
										</MKBox>
										<MKBox mb={2}>
											<Autocomplete
												disableClearable
												open={this.state.statusPersonOpen}
												onOpen={() => this.setState({ statusPersonOpen: true })}
												onClose={() => this.setState({ statusPersonOpen: false })}
												onFocus={() => this.setState({ statusPersonOpen: true })}
												options={this.state.statusPersons}
												value={this.state.statusPerson}
												onChange={(e, value) => {
													console.log({ value })
													if (value) {
														this.setState({
															statusPerson: value,
															error: { ...this.state.error, statusPerson: false },
															success: { ...this.state.success, statusPerson: true }
														})
													}
												}}
												sx={{
													".MuiAutocomplete-input": {
														padding: "7.5px 5px 7.5px 8px !important"
													},
													".MuiOutlinedInput-root": {
														padding: "4px !important"
													}
												}}
												isOptionEqualToValue={(option, value) => option.id === value.id}
												renderInput={(params) => <MKInput placeholder="Status Pernikahan" {...params} />}
											/>
										</MKBox>
										<MKBox mb={2}>
											<MKInput
												id="placeBirth"
												type="text"
												placeholder="Tempat Lahir"
												value={this.state.placeBirth}
												onChange={this.handleChange}
												onBlur={this.handleblur}
												success={this.state.success ? this.state.success.placeBirth : false}
												error={this.state.error ? this.state.error.placeBirth : false}
												fullWidth
											/>
										</MKBox>
										<MKBox mb={2}>
											<MKDatePicker
												input={{
													id: "dateBirth",
													placeholder: "Tanggal Lahir",
													fullWidth: true
												}}
												value={this.state.dateBirth}
												onChange={(value) => {
													const date = moment(value[0]).format('YYYY-MM-DD')
													this.setState({
														dateBirth: date,
														error: { ...this.state.error, dateBirth: false },
														success: { ...this.state.success, dateBirth: true }
													})
												}}
											/>
										</MKBox>
										<MKBox mb={2}>
											<MKInput
												id="phone"
												type="text"
												placeholder="Nomor Telpon"
												value={this.state.phone}
												onChange={this.handleChange}
												onBlur={this.handleblur}
												success={this.state.success ? this.state.success.phone : false}
												error={this.state.error ? this.state.error.phone : false}
												fullWidth
											/>
										</MKBox>
										<MKBox mb={2}>
											<MKInput
												id="email"
												type="text"
												placeholder="Email"
												value={this.state.email}
												onChange={this.handleChange}
												onBlur={this.handleblur}
												success={this.state.success ? this.state.success.email : false}
												error={this.state.error ? this.state.error.email : false}
												fullWidth
											/>
										</MKBox>
										<MKBox mb={2}>
											<MKInput
												id="nik"
												type="text"
												placeholder="Nomor KTP"
												value={this.state.nik}
												onChange={this.handleChange}
												onBlur={this.handleblur}
												fullWidth
											/>
										</MKBox>
										<MKBox mb={2}>
											<MKInput
												id="nokk"
												type="text"
												placeholder="Nomor Kartu Keluarga"
												value={this.state.nokk}
												onChange={this.handleChange}
												onBlur={this.handleBlur}
												fullWidth
											/>
										</MKBox>
									</Grid>
									<Grid item xs={12} md={6} lg={6}>
										<MKBox mb={2}>
											<Autocomplete
												fullWidth
												id="province"
												open={this.state.provinceOpen}
												onOpen={() => this.setState({ provinceOpen: true })}
												onClose={() => this.setState({ provinceOpen: false })}
												onFocus={() => this.setState({ provinceOpen: true })}
												value={this.state.province}
												options={this.state.provinces}
												onChange={(e, newValue) => {
													if (newValue) {
														this.setState({
															province: newValue,
															error: { ...this.state.error, province: false },
															success: { ...this.state.success, province: true }
														})
														this.loadDistrict(newValue.id)
													}
												}}
												sx={{
													".MuiAutocomplete-input": {
														padding: "7.5px 5px 7.5px 8px !important"
													},
													".MuiOutlinedInput-root": {
														padding: "4px !important"
													}
												}}
												isOptionEqualToValue={(option, value) => option.id === value.id}
												renderInput={(params) => <MKInput placeholder="Pilih Provinsi" {...params} />}
											/>
										</MKBox>
										<MKBox mb={2}>
											<Autocomplete
												fullWidth
												id="district"
												open={this.state.districtOpen}
												onOpen={() => this.setState({ districtOpen: true })}
												onClose={() => this.setState({ districtOpen: false })}
												onFocus={() => this.setState({ districtOpen: true })}
												value={this.state.district}
												options={this.state.districts}
												onChange={(e, value) => {
													if (value) {
														this.setState({
															district: value,
															error: { ...this.state.error, district: false },
															success: { ...this.state.success, district: true }
														})
													}
												}}
												sx={{
													".MuiAutocomplete-input": {
														padding: "7.5px 5px 7.5px 8px !important"
													},
													".MuiOutlinedInput-root": {
														padding: "4px !important"
													}
												}}
												isOptionEqualToValue={(option, value) => option.id === value.id}
												renderInput={(params) => <MKInput placeholder="Pilih Kabupaten" {...params} />}
											/>
										</MKBox>
										<MKBox mb={2}>
											<MKInput
												id="address"
												type="text"
												rows={3}
												multiline
												placeholder="Alamat"
												value={this.state.address}
												onChange={this.handleChange}
												onBlur={this.handleblur}
												success={this.state.success ? this.state.success.address : false}
												error={this.state.error ? this.state.error.address : false}
												fullWidth
											/>
										</MKBox>
										<MKBox mb={2}>
											<MKInput
												id="kodepos"
												type="text"
												placeholder="Kode POS"
												value={this.state.kodepos}
												onChange={this.handleChange}
												onBlur={this.handleblur}
												success={this.state.success ? this.state.success.kodepos : false}
												error={this.state.error ? this.state.error.kodepos : false}
												fullWidth
											/>
										</MKBox>
										<MKBox mb={2}>
											<input
												type="file"
												name="fileInput"
												ref={this.fileRef}
												onChange={(e) => {
													if (e.target.files.length === 1) {
														const file = e.target.files[0];
														const filename = file.name;
														const ext = filename.split(".")[1];
														this.setState({
															file, filename
														})
													}
												}}
												hidden
											/>
											<MKInput
												fullWidth
												value={this.state.filename}
												placeholder="Pilih Gambar"
												onClick={() => {
													this.fileRef.current.click()
												}}
												readOnly
											/>
										</MKBox>
										<MKBox mb={2}>
											<MKInput
												id="password"
												type="password"
												placeholder="Password"
												value={this.state.password}
												onChange={this.handleChange}
												onBlur={this.handleblur}
												success={this.state.success ? this.state.success.password : false}
												error={this.state.error ? this.state.error.password : false}
												fullWidth
											/>
										</MKBox>
										<MKBox mb={2}>
											<MKInput
												id="repassword"
												type="password"
												placeholder="Konfirmasi Password"
												value={this.state.repassword}
												onChange={this.handleChange}
												onBlur={this.handleblur}
												success={this.state.success ? this.state.success.repassword : false}
												error={this.state.error ? this.state.error.repassword : false}
												fullWidth
											/>
										</MKBox>
										<MKBox mb={2}>
											<MKInput
												id="invitationId"
												type="text"
												placeholder="Kode Referral"
												value={this.state.invitationId}
												onChange={this.handleChange}
												onBlur={this.handleblur}
												success={this.state.success ? this.state.success.invitationId : false}
												error={this.state.error ? this.state.error.invitationId : false}
												fullWidth
											/>
										</MKBox>
									</Grid>
								</Grid>
							</MKBox>
						</MKBox>
						<MKBox p={3} display="flex" justifyContent="flex-end">
							<MKBox mr={1}>
								<MKButton
									variant="gradient"
									color="error"
									size="large"
									component={Link}
									to={{ pathname: "/" }}
								>
									KEMBALI
								</MKButton>
							</MKBox>
							<MKButton
								type="button"
								variant="gradient"
								color="info"
								size="large"
								disabled={this.state.disabledSubmit}
								onClick={this.handleSumit}
							>
								DAFTAR
							</MKButton>
						</MKBox>
					</Card>
				</MKBox>
				<Footer menus={footerMenus} />
			</>
		)
	}
}

export default Register