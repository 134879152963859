import React, { useRef, useEffect } from "react"
import { Link } from "react-router-dom"

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// rellax
import Rellax from "rellax";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKButton from "components/MKButton";
import MKInput from "components/MKInput";

import Navbar from "contents/Components/Navbar";
import Footer from "contents/Components/Footer"

import bgImage from "assets/img/slider-bgp.png"

import { getMenus } from "routes";
import footerRoutes from "footer.routes";

function PrivacyPolicy() {
	const headerRef = useRef(null);

	useEffect(() => {
		const parallax = new Rellax(headerRef.current, {
			speed: -6,
		});

		return () => parallax.destroy();
	}, [])

	let routes = getMenus().map(item => ({
		...item,
		show: false,
	}))
	routes.push({
		name: "HOME",
		route: "/",
		key: "home",
		show: true,
		type: "target"
	})

	const footerMenus = footerRoutes.menus.map(item => ({ ...item, show: false })).filter(item => item.show)
	return (
		<>
			<Navbar routes={routes} action={true} />
			<MKBox component="section" pb={12}>
				<MKBox
					ref={headerRef}
					display="flex"
					alignItems={{ md: "center", xs: "center" }}
					justifyContent="center"
					minHeight="30vh"
					p={5}
					sx={{
						backgroundImage: ` url(${bgImage})`,
						backgroundSize: "cover",
						backgroundPosition: "center",
					}}
				>
					<Container>
						<Grid container item xs={12} lg={12} alignItems="center" flexDirection="column">
							<MKTypography
								variant="h1"
								// color="white"
								sx={({ breakpoints, typography: { size } }) => ({
									[breakpoints.down("md")]: {
										fontSize: size["3xl"],
									},
								})}
							>
								Kebijakan Privasi
							</MKTypography>
						</Grid>
					</Container>
				</MKBox>
				<Card
					sx={{
						p: 2,
						mx: { xs: 5, lg: 10 },
						mt: -5,
						mb: 4,
						backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.9),
						backdropFilter: "saturate(200%) blur(30px)",
						boxShadow: ({ boxShadows: { xxl } }) => xxl,
					}}
				>
					{/* <MKBox
						variant="gradient"
						bgColor="dark"
						borderRadius="lg"
						coloredShadow="dark"
						p={3}
						mt={-3}
						mx={2}
					>
						<MKTypography variant="h3" color="white">
							Kebijakan Privasi
						</MKTypography>
					</MKBox> */}
					<MKBox pb={6} px={6}>
						<MKTypography variant="h5" mt={6} mb={3}>
							Pengantar
						</MKTypography>
						<MKTypography variant="body2" color="text">
							<MKTypography
								variant="body2"
								color="dark"
								component="a"
								href="https://www.creative-tim.com"
								target="_blank"
								rel="noreferrer"
								sx={{ "&:hover": { color: ({ palette: { info } }) => info.main } }}
							>
								MABRUR{" "}
							</MKTypography>
							merupakan software yang ditujukan untuk perusahaan travel umrah dan haji, membuat Kebijakan Privasi ini untuk menunjukkan komitmen kami terhadap perlindungan privasi setiap pengunjung website ini. Silahkan dibaca dengan seksama Kebijakan Privasi website ini. Kebijakan Privasi ini menjelaskan tentang bagaimana kami menggunakan dan melindungi setiap informasi yang anda berikan saat menggunakan website ini. Seluruh data anda yang ada pada website ini hanya digunakan untuk mendukung transaksi anda, dan tidak diperjualbelikan kepada pihak lain.
							<br />
							<br />
							If you have additional questions or require more information about our Privacy
							Policy, do not hesitate to contact us through email at hello@creative-tim.com
							<br />
							<br />
							This privacy policy applies only to our online activities and is valid for
							visitors to our website with regards to the information that they shared and/or
							collect in{" "}
							<MKTypography
								variant="body2"
								color="dark"
								component="a"
								href="https://www.creative-tim.com"
								target="_blank"
								rel="noreferrer"
								sx={{ "&:hover": { color: ({ palette: { info } }) => info.main } }}
							>
								Creative Tim
							</MKTypography>
							. This policy is not applicable to any information collected offline or via
							channels other than this website.
						</MKTypography>
						<MKTypography variant="h5" mt={6} mb={3}>
							Managing Your Information
						</MKTypography>
						<MKTypography variant="body2" color="text">
							Unless otherwise stated, Creative Tim and/or its licensors own the intellectual
							property rights for all material on Creative Tim. All intellectual property
							rights are reserved. You may access this from Creative Tim for your own personal
							use subjected to restrictions set in these terms and conditions.
						</MKTypography>
						<MKTypography variant="body2" color="text">
							You must not:
						</MKTypography>
						<MKBox component="ul" my={3} ml={6}>
							<MKBox component="li">
								<MKTypography variant="body2" color="text" fontWeight="regular">
									Republish material from Creative Tim
								</MKTypography>
							</MKBox>
							<MKBox component="li">
								<MKTypography variant="body2" color="text" fontWeight="regular">
									Sell, rent or sub-license material from Creative Tim
								</MKTypography>
							</MKBox>
							<MKBox component="li">
								<MKTypography variant="body2" color="text" fontWeight="regular">
									Reproduce, duplicate or copy material from Creative Tim
								</MKTypography>
							</MKBox>
							<MKBox component="li">
								<MKTypography variant="body2" color="text" fontWeight="regular">
									Redistribute content from Creative Tim
								</MKTypography>
							</MKBox>
						</MKBox>
						<MKTypography variant="body2" color="text">
							This Agreement shall begin on the date hereof. Our Terms and Conditions were
							created with the help of the{" "}
							<MKTypography
								variant="body2"
								color="text"
								component="a"
								href="https://www.creative-tim.com"
								target="_blank"
								rel="noreferrer"
							>
								Terms And Conditions Generator
							</MKTypography>{" "}
							and the{" "}
							<MKTypography
								variant="body2"
								color="dark"
								component="a"
								href="https://www.creative-tim.com"
								target="_blank"
								rel="noreferrer"
								sx={{ "&:hover": { color: ({ palette: { info } }) => info.main } }}
							>
								Privacy Policy Generator
							</MKTypography>
							.
						</MKTypography>
						<MKTypography variant="body2" color="text">
							Parts of this website offer an opportunity for users to post and exchange
							opinions and information in certain areas of the website. Creative Tim does not
							filter, edit, publish or review Comments prior to their presence on the website.
							Comments do not reflect the views and opinions of Creative Tim,its agents and/or
							affiliates. Comments reflect the views and opinions of the person who post their
							views and opinions. To the extent permitted by applicable laws, Creative Tim
							shall not be liable for the Comments or for any liability, damages or expenses
							caused and/or suffered as a result of any use of and/or posting of and/or
							appearance of the Comments on this website.
						</MKTypography>
						<MKTypography variant="body2" color="text">
							Creative Tim reserves the right to monitor all Comments and to remove any
							Comments which can be considered inappropriate, offensive or causes breach of
							these Terms and Conditions.
						</MKTypography>
						<MKTypography variant="h5" mt={6} mb={3}>
							Security
						</MKTypography>
						<MKTypography variant="body2" color="text" fontWeight="regular">
							We shall not be hold responsible for any content that appears on your Website.
							You agree to protect and defend us against all claims that is rising on your
							Website. No link(s) should appear on any Website that may be interpreted as
							libelous, obscene or criminal, or which infringes, otherwise violates, or
							advocates the infringement or other violation of, any third party rights.
						</MKTypography>
					</MKBox>
				</Card>
			</MKBox>
			<Footer menus={footerMenus} />
		</>
	)
}

export default PrivacyPolicy