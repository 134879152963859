import React from 'react'
import PropTypes from "prop-types";
import Stack from "@mui/material/Stack";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

import MKBox from 'components/MKBox';
import MKTypography from 'components/MKTypography';
import MKButton from 'components/MKButton';

const Alert = React.forwardRef(function Alert(props, ref) {
	return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

class Notif extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			show: props.show,
			color: props.color,
			message: props.message
		}
	}

	handleClose = (event, reason) => {
		if (reason === "clickaway") {
			return;
		}
		this.setState({ show: false })
	};

	setShow(data) {
		this.setState({
			...data
		})
	}

	render() {
		return (
			<Stack spacing={2} sx={{ width: "100%" }}>
				<Snackbar open={this.state.show} autoHideDuration={3500} onClose={this.handleClose}>
					<Alert
						onClose={this.handleClose}
						severity={this.state.color}
						sx={{
							width: "100%",
							display: "flex",
							flexDirection: "row",
							justifyContent: "flex-start"
						}}
					>
						<MKTypography variant="body2" color="white" fontWeight="regular">
							{this.state.message}
						</MKTypography>
					</Alert>
				</Snackbar>
			</Stack>
		)
	}
}

Notif.defaultProps = {
	message: "",
	show: false,
	color: "warning",
}

Notif.propTypes = {
	message: PropTypes.string,
	show: PropTypes.bool,
	color: PropTypes.oneOf(["warning", "error", "info", "success"])
};

export default Notif