import PropTypes from "prop-types"

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

function IllustrationLayout({ title, image, children }) {

	return (
		<MKBox
			width="100vw"
			height="100%"
			minHeight="100vh"
			bgColor="white"
			sx={{ overflowX: "hidden" }}
		>
			<Grid
				container
				sx={{
					backgroundColor: ({ palette: { background, white } }) =>
						background.main
				}}
			>
				<Grid item xs={12} lg={6}>
					<MKBox
						display={{ xs: "none", lg: "flex" }}
						width="calc(100% - 2rem)"
						height="calc(100vh - 2rem)"
						borderRadius="lg"
						ml={2}
						mt={2}
						sx={{ backgroundImage: `url(${image})` }}
					/>
				</Grid>
				<Grid item xs={11} sm={8} md={6} lg={4} xl={3} sx={{ mx: "auto" }}>
					<MKBox display="flex" flexDirection="column" justifyContent="center" height="100vh">
						<MKBox py={3} px={3} textAlign="center">
							<MKBox mb={1} textAlign="center">
								<MKTypography variant="h4" fontWeight="bold">
									{title}
								</MKTypography>
							</MKBox>
						</MKBox>
						<MKBox p={3}>{children}</MKBox>
					</MKBox>
				</Grid>
			</Grid>
		</MKBox>
	)
}

IllustrationLayout.propTypes = {
	title: PropTypes.string.isRequired,
	image: PropTypes.string.isRequired,
	children: PropTypes.node.isRequired
}

export default IllustrationLayout