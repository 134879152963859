import React from "react"

import Fade from '@mui/material/Fade';

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Images
// import bgImage from "assets/images/bg-coworking.jpeg";
import bgImage from "assets/img/mabrur6.png"

import footerRoutes from "footer.routes";

import Config from "config"
import useAxios from "libs/useAxios";
import secureStorage from "libs/secureStorage";

import Navbar from "contents/Components/Navbar";
import Header from "contents/Components/Header"
import Services from "contents/Components/Services";
import AboutUs from "contents/Components/AboutUs"
import ContactUs from "contents/Components/ContactUs"
import Footer from "contents/Components/Footer"

import { getMenus } from "routes";
import LOGO from "assets/img/logoApp.png"
import DefaultNavbar from "examples/Navbars/DefaultNavbar";

class Home extends React.Component {

	constructor(props) {
		super(props)
		this.state = {
			isLoading: true,
			actionView: false,
			tagline: "",
			company: {
				address: "Jalan makam pahlawan 342, Semarang",
				email: "mabrur@gmail.com",
				phone: "089xxxxxxxxx",
				facebook: "",
				twitter: "",
				instagram: "",
			},
			aboutUs: {
				excerpt: "",
				message: ""
			},
		}

		this.servicesRef = React.createRef()
		this.aboutusRef = React.createRef()
		this.contactRef = React.createRef()
	}

	componentDidMount() {
		const profile = secureStorage.getItem('profile')
		if (profile) {
			this.setState({
				isLoading: false,
				company: profile.company,
				tagline: profile.tagline,
				aboutUs: profile.aboutUs
			})
		}
		else {
			this.loadData()
		}
		
	}

	loadData = () => {
		useAxios()
			.post(`${Config.ApiUrl}/profile/get`, { userId: Config.userId })
			.then(response => {
				const data = response.data
				const company = {
					address: data && data.address ? data.address : "",
					email: data && data.email ? data.email : "",
					phone: data && data.phone ? data.phone : "",
					facebook: data && data.facebook ? data.facebook : "",
					twitter: data && data.twitter ? data.twitter : "",
					instagram: data && data.instagram ? data.instagram : ""
				}

				const aboutUs = {
					excerpt: data && data.excerpt ? data.excerpt : "",
					message: data && data.message ? data.message : "",
				}

				this.setState({
					company,
					tagline: data && data.tagline ? data.tagline : "",
					aboutUs,
					isLoading: false
				})
			})
			.catch(err => {
				console.log(err)
			})
	}

	render() {
		const routes = getMenus()
		return (
			<>
				<Navbar routes={routes} />
				<Header tagline={this.state.tagline} />
				<Services />
				<AboutUs about={this.state.aboutUs} />
				<ContactUs content={this.state.company} />
				<Footer content={footerRoutes} />
			</>
		)
	}
}

export default Home