import { useState, createRef } from "react"
import PropTypes from "prop-types"

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card"

import ModalNotif from "contents/Components/ModalNotif";

// @mui icon
import PhoneIcon from "@mui/icons-material/Phone"
import EmailIcon from "@mui/icons-material/Email"
import LocationOnIcon from "@mui/icons-material/LocationOn"
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram"

import MKBox from "components/MKBox"
import MKTypography from "components/MKTypography";
import MKButton from "components/MKButton";
import MKInput from "components/MKInput";

import bgImage from "assets/img/bgCover2.jpeg"
import IconCamel from "assets/img/camel.png"

import Config from "config"
import useAxios from "libs/useAxios";

function ContactUs({content}) {
	const [name, setName] = useState("")
	const [email, setEmail] = useState("")
	const [phone, setPhone] = useState("")
	const [message, setMessage] = useState("")

	const modalNotifRef = createRef();

	const resetForm = ()=>{
		setName("")
		setEmail("")
		setPhone("")
		setMessage("")
	}
	const handleSubmit = ()=>{
		if(name === "" || email === "" || phone === "" || message === ""){
			modalNotifRef.current.setShow({
        modalTitle: "Gagal",
        modalMessage: "Data form masih belum lengkap, Silahkan di cek kembali!",
      });
		}else{
			const payload = {
				name,
				email,
				phone,
				message,
			}
			useAxios()
				.post(`${Config.ApiUrl}/contact/create`, payload)
				.then(response=>{
	
					modalNotifRef.current.setShow({
						modalTitle: "Sukses",
						modalMessage: response.data,
						onClose: () => {
							resetForm()
						}
					})
					
				})
				.catch((err) => {
					console.log(err)
					if (err.response) {
            modalNotifRef.current.setShow({
              modalTitle: "Gagal",
              modalMessage: err.response.data,
            });
          }
          // eslint-disable-next-line no-empty
          else {
            modalNotifRef.current.setShow({
              modalTitle: "Gagal",
              modalMessage: "Koneksi jaringan terputus",
            });
          }
				})
		}
	}

	return (
		<>
    <ModalNotif ref={modalNotifRef} />
		<MKBox
			id="contactus"
			component="section"
			py={{ xs: 0, lg: 0 }}
		>
			<Card
				id="contactus"
				sx={{
					mx: 5,
					my: 3,
					boxShadow: ({ boxShadows: { xxl } }) => xxl,
				}}
			>
				<Grid container item>
					<MKBox
						width="100%"
						bgColor="white"
						borderRadius="xl"
						shadow="xl"
						sx={{ overflow: "hidden" }}
					>
						<Grid container spacing={2}>
							<Grid item xs={12} md={7} lg={7}>
								<MKBox component="form" p={5}>
									<MKBox px={3} py={{ xs: 2, sm: 6 }}>
										<MKTypography variant="h2" mb={1}>
											Say Hi!
										</MKTypography>
										<MKTypography variant="body1" color="text" mb={2}>
											We&apos;d like to talk with you.
										</MKTypography>
									</MKBox>
									{/* FORM */}
									<MKBox pt={0.5} pb={3} px={3}>
										<Grid container>
											<Grid item xs={12} pr={1} mb={3}>
												<MKInput
													variant="standard"
													label="Nama Lengkap"
													value={name}
													onChange={(e) => setName(e.target.value)}
													InputLabelProps={{ shrink: true }}
													fullWidth
												/>
											</Grid>
											<Grid item xs={12} md={6} lg={6} pr={1} mb={3}>
												<MKInput
													variant="standard"
													label="Email"
													value={email}
													onChange={(e) => setEmail(e.target.value)}
													InputLabelProps={{ shrink: true }}
													fullWidth
												/>
											</Grid>
											<Grid item xs={12} md={6} lg={6} pr={1} mb={3}>
												<MKInput
													variant="standard"
													label="Telpon"
													value={phone}
													onChange={(e)=> setPhone(e.target.value)}
													InputLabelProps={{ shrink: true }}
													fullWidth
												/>
											</Grid>
											<Grid item xs={12} pr={1} mb={3}>
												<MKInput
													rows={3}
													multiline
													variant="standard"
													label="Pesan"
													value={message}
													onChange={(e)=> setMessage(e.target.value)}
													InputLabelProps={{ shrink: true }}
													fullWidth
												/>
											</Grid>
										</Grid>
										<Grid
											container
											item
											xs={12}
											md={6}
											justifyContent="flex-end"
											textAlign="right"
											ml="auto"
										>
											<MKButton variant="gradient" color="info" onClick={handleSubmit}>
												Kirim Pesan
											</MKButton>
										</Grid>
									</MKBox>
								</MKBox>
							</Grid>
							<Grid
								item
								xs={12}
								md={5}
								lg={5}
								position="relative"
								px={0}
								minHeight="100vh"
								sx={{
									backgroundImage: ({
										palette: { gradients },
										functions: { rgba, linearGradient },
									}) => `${linearGradient(rgba(gradients.info.main, 0.8), rgba(gradients.info.state, 0.8))}, url(${bgImage})`,
									backgroundSize: "cover",
								}}
							>
								<MKBox
									display="flex"
									justifyContent="center"
									alignItems="center"
									width="100%"
									height="100%"
								>
									<MKBox py={6} pr={6} pl={{ xs: 6, sm: 12 }} my="auto">
										<MKTypography variant="h3" color="white" mb={1}>
											Contact Information
										</MKTypography>
										<MKTypography variant="body2" color="white" opacity={0.8} mb={3}>
											Fill up the form and our Team will get back to you within 24 hours.
										</MKTypography>
										<MKBox display="flex" p={1}>
											<MKTypography variant="button" color="white">
												<PhoneIcon fontSize="small" />
											</MKTypography>
											<MKTypography
												component="span"
												variant="button"
												color="white"
												opacity={0.8}
												ml={2}
												fontWeight="regular"
											>
												{content.phone}
											</MKTypography>
										</MKBox>
										<MKBox display="flex" color="white" p={1}>
											<MKTypography variant="button" color="white">
												<EmailIcon fontSize="small" />
											</MKTypography>
											<MKTypography
												component="span"
												variant="button"
												color="white"
												opacity={0.8}
												ml={2}
												fontWeight="regular"
											>
												{content.email}
											</MKTypography>
										</MKBox>
										<MKBox display="flex" color="white" p={1}>
											<MKTypography variant="button" color="white">
												<LocationOnIcon fontSize="small" />
											</MKTypography>
											<MKTypography
												component="span"
												variant="button"
												color="white"
												opacity={0.8}
												ml={2}
												fontWeight="regular"
											>
												{content.address}
											</MKTypography>
										</MKBox>
										<MKBox mt={3}>
											<MKButton 
												variant="text"
                        color="white"
                        size="large"
                        component="a"
                        href={`${content.facebook}`}
                        target="_blank"
                        iconOnly
											>
												<FacebookIcon fontWeight="large" />
											</MKButton>
											<MKButton 
												variant="text"
                        color="white"
                        size="large"
                        component="a"
                        href={`${content.twitter}`}
                        target="_blank" iconOnly
											>
												<TwitterIcon fontWeight="large" />
											</MKButton>
											<MKButton 
												variant="text"
												color="white"
												size="large"
												component="a"
												href={`${content.instagram}`}
												target="_blank"
												iconOnly
											>
												<InstagramIcon fontWeight="large" />
											</MKButton>
										</MKBox>
									</MKBox>
								</MKBox>
							</Grid>
						</Grid>
					</MKBox>
				</Grid>
			</Card>
		</MKBox>
		</>
	)
}

ContactUs.defaultProps = {
	content: {
		address: "Jalan makam pahlawan 342, Semarang",
		email: "mabrur@gmail.com",
		phone: "089xxxxxxxxx",
		facebook: "https://www.facebook.com",
		twitter: "https://www.twitter.com",
		instagram: "https://www.instagram.com"
	},
}

ContactUs.propTypes = {
	content: PropTypes.object
}
export default ContactUs