import { useState } from "react"

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

import IconHotel from "assets/img/hotel.png"
import IconFlight from "assets/img/departure.png"
import IconShield from "assets/img/shield.png"
import IconCutlery from "assets/img/cutlery.png"
import IconLuggage from "assets/img/luggage.png"
import Transportation from "assets/img/transportation.png"

import MKBox from "components/MKBox"
import MKTypography from "components/MKTypography";

function Futures() {

	return (
		<MKBox
			id="services"
			component="section"
			display="flex"
			minHeight="100vh"
			sx={{
				backgroundColor: "#fff !important"
			}}
			py={8}
		>
			<Container>
				<Grid
					container
					item
					xs={12}
					lg={6}
					justifyContent="center"
					mx="auto"
					textAlign="center"
					pb={6}
					mb={5}
				>
					<MKBox
						display="flex"
						flexDirection="column"
						alignItems="center"
						justifyContent="center"
					>
						<MKTypography
							mb={1}
							variant="h2"
							textTransform="uppercase"
							sx={{
								textDecoration: "underline",
							}}
						>
							Services
						</MKTypography>
						<MKTypography
							variant="body2"
							fontWeight="regular"
							sx={{
								color: "#777",
								marginTop: 1
							}}
						>
							Daftar fasilitas yang kami sediakan untuk para kaum muslimin diseluruh Indonesia khususnya jamaah haji & umroh.
						</MKTypography>
					</MKBox>
				</Grid>
				<Grid container spacing={3}>
					<Grid item xs={12} lg={4} md={4}>
						<MKBox
							width="100"
							display="flex"
							flexDirection="column"
							alignItems="center"
						>
							<MKBox
								component="img"
								src={IconFlight}
								alt="hotel"
								maxWidth="5.6rem"
							/>
							<MKTypography
								variant="body"
								textTransform="uppercase"
								sx={{ marginTop: 1 }}
							>
								Tiket Perjalanan
							</MKTypography>
							<MKTypography variant="body2" sx={{ textAlign: "center", marginTop: 2 }}>
								Tiket pesawat PP untuk keperluan berangkat ke tanah suci
							</MKTypography>
						</MKBox>
					</Grid>
					<Grid item xs={12} lg={4} md={4}>
						<MKBox
							width="100"
							display="flex"
							flexDirection="column"
							alignItems="center"
						>
							<MKBox
								component="img"
								src={IconHotel}
								alt="hotel"
								maxWidth="5.6rem"
							/>
							<MKTypography
								variant="body"
								textTransform="uppercase"
								sx={{ marginTop: 1 }}
							>
								Hotel Penginapan
							</MKTypography>
							<MKTypography variant="body2" sx={{ textAlign: "center", marginTop: 2 }}>
								Akomodasi hotel / penginapan terbaik dan ternyaman
							</MKTypography>
						</MKBox>
					</Grid>
					<Grid item xs={12} lg={4} md={4}>
						<MKBox
							width="100"
							display="flex"
							flexDirection="column"
							alignItems="center"
						>
							<MKBox
								component="img"
								src={IconCutlery}
								alt="hotel"
								maxWidth="5.6rem"
							/>
							<MKTypography
								variant="body"
								textTransform="uppercase"
								sx={{ marginTop: 1 }}
							>
								Konsumsi
							</MKTypography>
							<MKTypography variant="body2" sx={{ textAlign: "center", marginTop: 2 }}>
								Konsumsi yang terjamin dari memulai perjalanan sampai selesai
							</MKTypography>
						</MKBox>
					</Grid>
					<Grid item xs={12} lg={4} md={4}>
						<MKBox
							width="100"
							display="flex"
							flexDirection="column"
							alignItems="center"
						>
							<MKBox
								component="img"
								src={IconLuggage}
								alt="hotel"
								maxWidth="5.6rem"
							/>
							<MKTypography
								variant="body"
								textTransform="uppercase"
								sx={{ marginTop: 1 }}
							>
								Perlengkapan
							</MKTypography>
							<MKTypography variant="body2" sx={{ textAlign: "center", marginTop: 2 }}>
								Paket umroh dengan perlengkapan kebutuhan ibadah yang lengkap
							</MKTypography>
						</MKBox>
					</Grid>
					<Grid item xs={12} lg={4} md={4}>
						<MKBox
							width="100"
							display="flex"
							flexDirection="column"
							alignItems="center"
						>
							<MKBox
								component="img"
								src={Transportation}
								alt="hotel"
								maxWidth="5.6rem"
							/>
							<MKTypography
								variant="body"
								textTransform="uppercase"
								sx={{ marginTop: 1 }}
							>
								Transportasi
							</MKTypography>
							<MKTypography variant="body2" sx={{ textAlign: "center", marginTop: 2 }}>
								Transportasi untuk memudahkan perjalanan jamaah
							</MKTypography>
						</MKBox>
					</Grid>
					<Grid item xs={12} lg={4} md={4}>
						<MKBox
							width="100"
							display="flex"
							flexDirection="column"
							alignItems="center"
						>
							<MKBox
								component="img"
								src={IconShield}
								alt="hotel"
								maxWidth="5.6rem"
							/>
							<MKTypography
								variant="body"
								textTransform="uppercase"
								sx={{ marginTop: 1 }}
							>
								Asuransi
							</MKTypography>
							<MKTypography variant="body2" sx={{ textAlign: "center", marginTop: 2 }}>
								Akomodasi asuransi bagi jamaah
							</MKTypography>
						</MKBox>
					</Grid>
				</Grid>
			</Container>
		</MKBox>
	)
}

export default Futures