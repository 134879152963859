// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// react-router-dom components
import { Link } from "react-router-dom";

// @mui material components
import Collapse from "@mui/material/Collapse";
import Icon from "@mui/material/Icon";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

import AnchorLink from 'react-anchor-link-smooth-scroll';

function DefaultNavbarDropdown({
	name,
	icon,
	children,
	collapseStatus,
	light,
	href,
	route,
	type,
	collapse,
	...rest
}) {
	const linkComponent = {
		component: "a",
		href,
	};

	const routeComponent = {
		component: Link,
		to: route,
	};

	return (
		<MKBox
			{...rest}
			mx={1}
			p={1}
			display="flex"
			alignItems="baseline"
			color={light ? "white" : "dark"}
			opacity={light ? 1 : 0.6}
			sx={{ cursor: "pointer", userSelect: "none" }}
			// {...(route && routeComponent)}
			{...(type === "target" && routeComponent)}
		>
			{type == "scroll" ? (
				<AnchorLink href={href}>
					<MKTypography
						variant="button"
						fontWeight="medium"
						textTransform="capitalize"
						color={light ? "white" : "dark"}
						sx={{ fontWeight: "100%", ml: 1, mr: 0.25 }}
					>
						{name}
					</MKTypography>
				</AnchorLink>
			) : (
				<MKBox
					pt={0.1}
				>
					<MKTypography
						variant="button"
						fontWeight="medium"
						textTransform="capitalize"
						color={light ? "white" : "dark"}
						sx={{ fontWeight: "100%", ml: 1, mr: 0.25 }}
					>
						{name}
					</MKTypography>
				</MKBox>
			)}
			{/* <MKTypography variant="body2" color={light ? "white" : "dark"} ml="auto">
          <Icon sx={{ fontWeight: "normal", verticalAlign: "middle" }}>
            {collapse && "keyboard_arrow_down"}
          </Icon>
        </MKTypography> */}
		</MKBox>
		// {/* {children && (
		//   <Collapse in={Boolean(collapseStatus)} timeout={400} unmountOnExit>
		//     {children}
		//   </Collapse>
		// )} */}
	);
}

// Setting default values for the props of DefaultNavbarDropdown
DefaultNavbarDropdown.defaultProps = {
	children: false,
	collapseStatus: false,
	light: false,
	href: "",
	route: "",
};

// Typechecking props for the DefaultNavbarDropdown
DefaultNavbarDropdown.propTypes = {
	name: PropTypes.string.isRequired,
	icon: PropTypes.node,
	children: PropTypes.node,
	collapseStatus: PropTypes.bool,
	light: PropTypes.bool,
	href: PropTypes.string,
	type: PropTypes.string,
	route: PropTypes.string,
	collapse: PropTypes.bool.isRequired,
};

export default DefaultNavbarDropdown;
