// Material Kit 2 PRO React components
import MKTypography from "components/MKTypography";

const date = new Date().getFullYear();
// Images
import LOGO from "assets/img/logoApp.png"

export default {
	brand: {
		name: "MABRUR",
		image: LOGO,
		route: "/",
	},
	menus: [
		{ name: "Services", href: "#services", type: "anchor", show: true },
		{ name: "About Us", href: "#aboutus", type: "anchor", show: true },
		{ name: "Contact Us", href: "#contactus", type: "anchor", show: true },
		{ name: "Privacy Policy", href: "/privacy-policy", type: "internal", show: true }
	]
}