import React from "react"

// react-router-dom components
import { Link, Navigate } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";

import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKInput from "components/MKInput";

import Notif from "contents/Components/Notif";
import IllustrationLayout from "contents/Login/IllustrationLayout";

import Config from "config"
import useAxios from "libs/useAxios";
import secureStorage from "libs/secureStorage";

import BgImage from "assets/images/bg-sign-in-basic.jpeg";
import bgImage from "assets/images/illustrations/illustration-reset.jpg";

class Login extends React.Component {

	constructor(props) {
		super(props)
		this.state = {
			username: "",
			password: "",

			disabledBtnLogin: false,
			redirect: null
		}

		this.notifRef = React.createRef()
	}

	componentDidMount() {
		const user = secureStorage.getItem('user')
		if (user) this.setState({ redirect: "/" })
	}

	handleKeyDown = (e) => {
		if (e.key === "Enter") this.handleLogin()
	}

	handleChange = (e) => {
		const { id, value } = e.target;
		if (id === "username") this.setState({ username: value });
		else this.setState({ password: value });
	}

	handleLogin = () => {
		if (this.state.username === "" || this.state.password === "") {
			this.notifRef.current.setShow({
				show: true,
				message: "Data masih ada yang kosong, Mohon di lengkapi dahulu",
				color: "warning"
			})
		}
		else {
			this.setState({ disabledBtnLogin: true })
			const payload = {
				username: this.state.username,
				password: this.state.password,
				merchantId: Config.merchantId
			}

			useAxios()
				.post(`${Config.ApiUrl}/user/login`, payload)
				.then(response => {
					const data = response.data
					secureStorage.setItem('user', data.user)
					secureStorage.setItem('token', data.token)
					this.setState({
						redirect: "/",
						disabledBtnLogin: false,
					})
				})
				.catch(err => {
					console.log(err)
					this.setState({
						disabledBtnLogin: false,
					}, () => {
						this.notifRef.current.setShow({
							show: true,
							message: err.response && err.response.data ? err.response.data : "Terjadi kesalahan pada system",
							color: 'warning'
						})
					})
				})
		}
	}

	render() {
		if (this.state.redirect) {
			return <Navigate to={this.state.redirect} />
		}

		return (
			<IllustrationLayout
				title="LOGIN"
				image={bgImage}
			>
				<Notif ref={this.notifRef} />
				<MKBox component="form" role="form">
					<MKBox mb={2}>
						<MKInput
							id="username"
							type="text"
							label="username"
							fullWidth
							onChange={this.handleChange}
							onKeyDown={this.handleKeyDown}
						/>
					</MKBox>
					<MKBox mb={2}>
						<MKInput
							id="password"
							type="password"
							label="Password"
							fullWidth
							onChange={this.handleChange}
							onKeyDown={this.handleKeyDown}
						/>
					</MKBox>
					<MKBox mt={4} mb={1}>
						<MKBox mb={1}>
							<MKButton
								variant="gradient"
								color="info"
								size="large"
								fullWidth
								disabled={this.state.disabledBtnLogin}
								onClick={this.handleLogin}
							>
								MASUK
							</MKButton>
						</MKBox>
						<MKButton
							variant="outlined"
							color="dark"
							size="large"
							fullWidth
							component={Link}
							to={{ pathname: "/" }}
						>
							HOME
						</MKButton>
					</MKBox>
				</MKBox>
			</IllustrationLayout >
		)
	}
}

export default Login