import { useEffect, useRef } from "react";
import { Link } from "react-router-dom"
import PropTypes from "prop-types"

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
// import Link from "@mui/material/Link";
import Divider from "@mui/material/Divider"

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 PRO React base styles
import typography from "assets/theme/base/typography";
import LOGO from "assets/img/logoApp.png"

import AnchorLink from 'react-anchor-link-smooth-scroll';

function Footer({ company, menus, light }) {

	const { href, name, image } = company;
	const { size } = typography;

	const renderMenus = () =>
		menus.map((menu, key) => (
			<MKBox
				key={menu.name}
				component="li"
				pl={key === 0 ? 0 : 2}
				pr={key === menus.length - 1 ? 0 : 2}
				lineHeight={1}
			>
				{menu.type === "anchor" ? (
					<AnchorLink href={menu.href}>
						<MKTypography variant="button" fontWeight="regular" color={light ? "white" : "dark"}>
							{menu.name}
						</MKTypography>
					</AnchorLink>
				) : (
					<Link to={{ pathname: menu.href }} >
						<MKTypography variant="button" fontWeight="regular" color={light ? "white" : "dark"}>
							{menu.name}
						</MKTypography>
					</Link>
				)}
			</MKBox >
		));

	return (
		<MKBox
			component="footer"
			py={5}
			sx={{
				backgroundSize: "cover",
				backgroundPosition: "center",
				// backgroundColor: "rgba(30,30,30,.97)",
				// color: "#ddd"
			}}
		>
			<Container>
				<MKBox
					width="100%"
					display="flex"
					flexDirection={{ xs: "column", lg: "row" }}
					justifyContent="space-between"
					alignItems="center"
				>
					<MKBox
						display="flex"
						flexDirection="row"
						justifyContent="center"
						alignItems="center"
						flexWrap="wrap"
						color={light ? "white" : "dark"}
						fontSize={size.sm}
					>
						{/* <Link to={href}> */}
						<MKBox component="img" src={image} alt={name} maxWidth="2rem" />
						{/* </Link> */}
						<MKTypography
							variant="h6"
							color={light ? "white" : "dark"}
							sx={{ ml: 2 }}
						>
							{name}
						</MKTypography>
					</MKBox>
					<MKBox
						component="ul"
						sx={({ breakpoints }) => ({
							display: "flex",
							flexWrap: "wrap",
							alignItems: "center",
							justifyContent: "center",
							listStyle: "none",
							mt: 3,
							mb: 0,
							p: 0,

							[breakpoints.up("lg")]: {
								mt: 0,
							},
						})}
					>
						{renderMenus()}
					</MKBox>
				</MKBox>
				<Divider
					light
					sx={{ height: "0.1rem", backgroundColor: "#000" }}
				/>
				{/* COPYRIGHT */}
				<MKBox
					width="100%"
					display="flex"
					justifyContent="center"
				>
					<MKTypography
						variant="button"
						color={light ? "white" : "dark"}
						fontWeight="regular"
					>
						All rights reserved. Copyright &copy; 2022
						<MKTypography
							component="a"
							href="#"
							target="_blank"
							rel="noreferrer"
							variant="button"
							color={light ? "white" : "dark"}
							fontWeight="regular"
						>
							&nbsp; MABRUR
						</MKTypography>
						.
					</MKTypography>
				</MKBox>
			</Container>
		</MKBox>
	)
}

// Setting default values for the props of SimpleFooter
Footer.defaultProps = {
	company: {
		href: "/",
		image: LOGO,
		name: "MABRUR",
	},
	menus: [
		{ name: "Services", href: "#services", type: "anchor" },
		{ name: "About Us", href: "#aboutus", type: "anchor" },
		{ name: "Contact Us", href: "#contactus", type: "anchor" },
		{ name: "Privacy Policy", href: "/privacy-policy", type: "internal" }
	],
	light: false,
};

// Typechecking props for the SimpleFooter
Footer.propTypes = {
	company: PropTypes.objectOf(PropTypes.string),
	menus: PropTypes.arrayOf(PropTypes.object),
	light: PropTypes.bool,
};

export default Footer