/* eslint-disable react/prop-types */
import React, { createRef } from "react";

// react-router components
import { Routes, Route, Navigate } from "react-router-dom";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Button from "@mui/material/Button";

// Material Kit 2 PRO React themes
import theme from "assets/theme";

import { withSnackbar } from "notistack";

import { routes } from "routes";
// import secureStorage from "libs/secureStorage";

import Login from "contents/Login"
import Layout from "contents"

import * as serviceWorker from "serviceWorker"

class App extends React.Component {

	constructor(props) {
		super(props)
		this.state = {
			waitingWorker: {},
			newVersionAvailable: false,
		}
	}

	componentDidMount() {
		document.documentElement.scrollTop = 0;
		document.scrollingElement.scrollTop = 0;
	}

	UNSAFE_componentWillMount() {
		serviceWorker.register({ onUpdate: this.onServiceWorkerUpdate })
	}

	onServiceWorkerUpdate = (waitingWorker) => {
		console.log(this.props)
		const { enqueueSnackbar } = this.props;
		this.setState({
			waitingWorker,
			newVersionAvailable: true,
		});

		enqueueSnackbar("Versi terbaru telah tersedia", {
			persist: true,
			variant: "success",
			action: this.refreshAction(),
		});
	};

	refreshAction = (key) => {
		return (
			<React.Fragment>
				<Button color="error" size="small" onClick={this.updateServiceWorker}>
					{"update"}
				</Button>
			</React.Fragment>
		);
	};

	updateServiceWorker = () => {
		const { waitingWorker } = this.state;

		waitingWorker && waitingWorker.postMessage("forceUpdate");
	};

	getRoutes = (allRoutes) => {
		const index = [];
		const theRoutes = allRoutes.map((route) => {
			if (route.index) index.push(<Route index element={route.component} key={route.key} />);
			if (route.collapse) {
				return (
					<Route exact path={route.route} element={route.component} key={route.key}>
						{this.getRoutes(route.collapse)}
					</Route>
				);
			} else if (route.route) {
				return <Route exact path={route.route} element={route.component} key={route.key} />;
			}
			return null;
		});
		return theRoutes.concat(index);
	}

	render() {
		const routeTemp = this.getRoutes(routes);
		return (
			<ThemeProvider theme={theme}>
				<CssBaseline />
				<Routes>
					{/* <Route exact path="/login" element={<Login />} key="login" /> */}
					<Route path="/" element={<Layout key="layout" />}>
						{routeTemp}
					</Route>
					<Route path="*" element={<Navigate to="/" />} />
				</Routes>
			</ThemeProvider>
		)
	}
}

export default withSnackbar(App)