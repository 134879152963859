import React from "react";
import { Outlet, Navigate } from "react-router-dom"

import Container from "@mui/material/Container"

import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

import DefaultNavbar from "examples/Navbars/DefaultNavbar";

import Loader from "contents/Components/Loader"

import secureStorage from "libs/secureStorage";
import Config from "config";
import useAxios from "libs/useAxios";

import { getMenus } from "routes";
import LOGO from "assets/img/logoApp.png"

class Layout extends React.Component {

	constructor(props) {
		super(props)
		this.state = {
			user: null,
			merchantName: "",
			logoMain: null,

			isLoading: false,
			redirect: null
		}
	}

	componentDidMount() {
		this.loadData()
		const user = secureStorage.getItem('user')
		this.setState({ user })
	}

	loadData = () => {
		useAxios()
			.post(`${Config.ApiUrl}/profile/get`, { userId: Config.userId })
			.then(response => {
				const data = response.data
				const profile = {
					tagline: data.tagline,
					aboutUs: {
						excerpt: data.excerpt,
						message: data.message,
					},
					company: {
						phone: data.phone,
						email: data.email,
						address: data.address,
						facebook: data.facebook,
						twitter: data.twitter,
						instagram: data.instagram
					},
				}
				secureStorage.setItem('profile', profile)

				this.setState({
					isLoading: false,
				})
			})
			.catch(err => {
				console.log(err)
			})
	}

	handleLogout = () => {
		secureStorage.removeItem('user')
		this.setState({ user: null })
	}

	render() {
		const action = this.state.user ? {
			type: "internal",
			onClick: this.handleLogout,
			route: "/dashboard",
			label: "keluar",
			color: "error",
		} : null

		if (this.state.redirect) {
			return <Navigate to={this.state.redirect} />
		}

		return this.state.isLoading ? <Loader open={true} /> : (
			<>
				{/* <MKBox
					bgColor="#00acc1"
					shadow="sm"
				>
					<DefaultNavbar
						routes={getMenus()}
						brand={
							<MKBox
								display="flex"
								justifyContent="flex-start"
								alignItems="center"
							>
								<MKBox
									component="img"
									src={`${this.state.logoMain ? this.state.logoMain : LOGO}`}
									sx={{
										height: 30,
										width: 30,
										marginRight: "5px"
									}}
								/>
								<MKTypography
									variant="button"
									fontWeight="medium"
									color="white"
								>
									{this.state.merchantName.toUpperCase()}
								</MKTypography>
							</MKBox>
						}
						action={action}
						light
						transparent
					/>
				</MKBox> */}
				<Outlet />
			</>
		)
	}
}

export default Layout